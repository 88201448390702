import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import NumberFormat from "react-number-format";
import { Cancel } from "@material-ui/icons";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EditAcounts(props) {
  const classes = useStyles();

  const data = props.id;
  const [returnLogin, setReturnLogin] = React.useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [errorServer, setErrorServer] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    name: data.name,
    lastName: data.lastName,
    document_id: data.documentId !== null ? data.documentId : "",
    email: data.email,
    address: data.address,
    phone: data.mobileNumber,
    birthdate: data.birthdate,
    errorName: false,
    errorAddress: false,
    errorPhone: false,
    errorDocument_id: false,
    errorEmail: false,
    errorLastName: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorAddress: false,
      errorPhone: false,
      errorDocument_id: false,
      errorEmail: false,
      errorLastName: false,
    });
    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setOpenAlert(false);
    setErrorServer(false);

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombre" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.lastName.length === 0) {
      setValues({ ...values, errorLastName: true });
      setMensaje('El campo "Apellido" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.document_id.length === 0) {
      setValues({ ...values, errorDocument_id: true });
      setMensaje('El campo "Documento" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.email.length === 0) {
      setValues({ ...values, errorEmail: true });
      setMensaje('El campo "Email" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.phone === "") {
      setValues({ ...values, errorPhone: true });
      setMensaje('El campo "Teléfono" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.address === "") {
      setValues({ ...values, errorAddress: true });
      setMensaje('El campo "Dirección" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else {
      const dataValue2 = {
        documentId: values.document_id.toString(),
        name: values.name,
        lastName: values.lastName,
        address: values.address,
        phoneNumber: values.phone.toString(),
        mobileNumber: values.phone.toString(),
        email: values.email,
      };

      axios
        .put(`${urlServices}users/${data.id}`, dataValue2, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setMensaje("Modificado con exito.");
          setOpenAlert(true);
          setError("success");
          props.callBackRefresh();
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 200);
          } else {
            setErrorServer(true);
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {errorServer ? (
                  <Alert severity="error">
                    Error al registrar en el servidor
                  </Alert>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>Editar cuenta</div>
                    <div className={classes.lineBottom}></div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange("name")}
                        fullWidth
                        required
                        label="Nombres"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="lastName"
                        value={values.lastName}
                        error={values.errorLastName}
                        onChange={handleChange("lastName")}
                        fullWidth
                        required
                        label="Apellidos"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="document_id"
                      value={values.document_id}
                      onChange={handleChange("document_id")}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          fontSize: 16,
                          marginTop: 25,
                        },
                      }}
                      label="Documento"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      helperText="Cédula, RUC o pasaporte."
                      error={values.errorDocument_id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      label="E-mail"
                      error={values.errorEmail}
                      className={classes.textField2}
                      fullWidth
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="phone"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          fontSize: 16,
                        },
                      }}
                      label="Teléfono"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      error={values.errorPhone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="birthdate"
                      value={values.birthdate}
                      label="Cumpleaño"
                      fullWidth
                      disabled
                      style={{ marginTop: 5 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="address"
                        value={values.address}
                        error={values.errorAddress}
                        onChange={handleChange("address")}
                        fullWidth
                        required
                        label="Dirección"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
