import React, { Fragment } from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";

import Header from "../components/Navbars/HeaderHome";
import Footer from "../components/Footer/FooterAccount";

import img from "assets/img/undraw_towing_6yy4.png";

import { makeStyles } from "@material-ui/core/styles";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function Nofoundpage(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <center>
        <br></br>
        <br></br>
        <Grid container className={classes.carContacts}>
          <Grid item xs={12} sm={6}>
            <Avatar
              alt="404"
              src={img}
              className={classes.img404Profile}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <br></br>
            <br></br>
            <div className={classes.titleCard404}>Página no encontrada</div>
            <div className={classes.lineBottom}></div>
            <br></br>
            <br></br>
            <Link href="/login" color="inherit" className={classes.blockReds}>
              <Button className={classes.buttonSubmit}>Ir al inicio</Button>
            </Link>
          </Grid>
        </Grid>
      </center>

      <Grid container>
        <Footer />
      </Grid>
    </Fragment>
  );
}
