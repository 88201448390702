/*eslint-disable*/
import React, { Fragment, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import Icon from "@material-ui/core/Icon";
import { ArrowRight, ExpandMore, ExpandLess } from "@material-ui/icons";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function SidebarAdmin(props) {
  const classes = useStyles();

  const [hide, setHide] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(false);
    setHide(false);
  };

  const handleOpen = (pro) => () => {
    setOpen(pro);
    setHide(!hide);
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else if (prop.path === "") {
          listItemClasses = "";
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return prop.name !== "" ? (
          prop.submenu.length === 0 ? (
            <Fragment>
              <NavLink
                to={prop.layout + prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              >
                <ListItem
                  button
                  className={classes.itemLink + listItemClasses}
                  onClick={handleClick}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}
                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: props.rtlActive,
                    })}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            </Fragment>
          ) : (
            <Fragment>
              <ListItem
                button
                onClick={handleOpen(prop.id)}
                className={classes.itemLinkCollapse}
              >
                {typeof prop.icon === "string" ? prop.icon : <prop.icon />}
                <ListItemText
                  primary={prop.name}
                  className={classes.itemLinkCollapseName}
                  disableTypography={true}
                />
                {open === prop.id ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse
                in={open === prop.id ? hide : false}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "rgb(247, 247, 247)" }}
              >
                <List component="div" key={key}>
                  {prop.submenu.map((prop2) => {
                    if (prop2.subId === prop.id) {
                      return (
                        <NavLink
                          to={prop.layout + prop2.path}
                          className={activePro + classes.item}
                          activeClassName="active"
                          key={key}
                        >
                          <ListItem
                            button
                            className={classes.itemLinkCollapseSubmenu}
                          >
                            <ArrowRight
                              style={{
                                minWidth: " 0px !important",
                                color: "#000",
                              }}
                            />
                            <ListItemText
                              primary={prop2.name}
                              className={classes.itemLinkCollapseNameSub}
                              disableTypography={true}
                            />
                          </ListItem>
                        </NavLink>
                      );
                    }
                  })}
                </List>
              </Collapse>
            </Fragment>
          )
        ) : null;
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <img src={logo} alt="logo" className={classes.imgLogoRadius} />
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          onClose={props.handleDrawerToggle}
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : (
            <div className={classes.background} />
          )}
        </Drawer>
      </Hidden>
    </div>
  );
}

SidebarAdmin.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
