import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Person } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";

import imgLoader from "assets/img/progress.gif";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function ProfilesListAdmin() {
  const classes = useStyles();
  const tableRef = React.createRef();

  const [rows, setRows] = useState([]);

  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "ID",
        field: "id",
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
      {
        title: "Nombre",
        field: "name",
        render: (rowData) => {
          return <Fragment>{rowData.name}</Fragment>;
        },
        width: "15%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          textAlign: "left",
          fontSize: 12,
        },
        headerStyle: { textAlign: "center" },
      },
      {
        title: "Estado",
        field: "status",
        filtering: false,
        render: (rowData) => {
          const text = rowData.status === 1 ? "ACTIVO" : "INACTIVO";
          const color = rowData.status === 1 ? "green" : "red";
          return <b style={{ color: color }}>{text}</b>;
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 11,
        },
      },
    ],
  });

  useEffect(() => {
    dataRows();
  }, []);

  const dataRows = () => {
    axios
      .get(`${urlServices}profiles`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRows(response.data);
          setLoader(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([]);
          setLoader(false);
        } else {
          setRows([]);
          setError(true);
          console.log(e);
        }
      });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Person className={classes.iconWhite} /> Listado de Perfiles
            </div>
          </CardHeader>
          <CardBody>
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ""
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      src={imgLoader}
                      alt="loader"
                      style={{
                        width: 50,
                        margin: "100px 0",
                        objectFit: "contain",
                      }}
                    />
                  </center>
                </Grid>
              </Grid>
            ) : (
              <MaterialTable
                title=""
                tableRef={tableRef}
                columns={state.columns}
                style={{
                  boxShadow: "unset",
                  border: "1px solid rgb(241, 241, 241)",
                }}
                data={rows}
                localization={{
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: " {from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Previo",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima página",
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: "Filtrar",
                    searchPlaceholder: "Buscar",
                  },
                  header: {
                    actions: " --- ",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos.",
                    filterRow: {
                      filterTooltip: "Filtro",
                    },
                  },
                }}
                icons={tableIcons}
                options={{
                  search: true,
                  padding: "default",
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: "#F2F2F2",
                    padding: "1%",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 12,
                  },
                  cellStyle: { border: "1px solid #DDDDDD" },
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
