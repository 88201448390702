import React, { useState, useContext, useEffect, Fragment } from "react";
import { Redirect } from "react-router-dom";

import { UrlServicesContext } from "components/UrlServicesContext";

import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ArrowRightAlt, Visibility, VisibilityOff } from "@material-ui/icons";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import { Animated } from "react-animated-css";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import logo from "assets/img/logomain.jpeg";
import banner from "assets/img/bannerLeft.png";

import PasswordRecovery from "./dialog/PasswordRecovery";

import {
  cardBodyStyle,
  progressLinear,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);
const ColorLinearProgress = withStyles(progressLinear)(LinearProgress);

export default function Login() {
  const classes = useStyles();

  const [loaderLogin, setLoaderLogin] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [passwordRecovery, setpasswordRecovery] = useState(false);
  const [errorFieldLogin, setErrorFieldLogin] = useState(false);
  const [ipPublic, setipPublic] = useState("");

  const [values, setValues] = useState({
    mail: "",
    captcha: "",
    password: "",
    newPassword: "",
    status: "",
    session: "",
    id: "",
    profile_id: "",
    allname: "",
    user: "",
    showPassword: false,
    errorMail: false,
    errorCaptcha: false,
  });

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [alert, setAlert] = useState({
    openAlert: false,
    mensaje_error: "",
    loggedIn: localStorage.getItem("Session") === null ? false : true,
  });

  useEffect(() => {  
    axios.get(`https://api.ipify.org?format=json`).then(
      (responseIP) => {
        setipPublic(responseIP.data.ip);
      },
      (error) => {
        console.log(error + " ERROR DE IP");
      }
    );

    loadCaptchaEnginge(4, "#bdbdbd", "#000", "upper");
  }, []);

  const handleChange = (prop) => (event) => {
    setErrorFieldLogin(false);
    setAlert({
      ...alert,
      openAlert: false,
    });
    setValues({
      ...values,
      [prop]: event.target.value,
      errorCaptcha: false,
      errorMail: false,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitEmail = () => (event) => {
    event.preventDefault();
    setLoaderLogin(true);

    if (values.mail.length === 0) {
      setValues({ ...values, errorMail: true });
      setLoaderLogin(false);
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "Correo electrónico es requerido.",
      });
    } else if (values.captcha.length === 0) {
      setLoaderLogin(false);

      setValues({ ...values, errorCaptcha: true });

      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "Código captcha requerido.",
      });
    } else if (validateCaptcha(values.captcha) !== true) {
      setLoaderLogin(false);

      setValues({ ...values, captcha: "", errorCaptcha: true });

      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "Código no válido del captcha.",
      });
      loadCaptchaEnginge(4, "#bdbdbd", "#000", "upper");
    } else {
      setValues({ ...values, captcha: "" });

      let data = {
        email: values.mail.toLowerCase().trim(),
      };

      axios
        .post(`${urlServices}auth/email`, data, {
          headers: {
            application: keyAplication,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              setLoaderLogin(false);
              setValidateEmail(true);
            }, 200);
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setValues({ ...values, errorMail: true });
            setTimeout(() => {
              setLoaderLogin(false);
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Correo electrónico no registrado.",
              });
            }, 200);
          } else if (e.response.status === 400) {
            setValues({ ...values, errorMail: true });
            setTimeout(() => {
              setLoaderLogin(false);
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "El correo electrónico no es válido.",
              });
            }, 200);
          } else {
            setTimeout(() => {
              setLoaderLogin(false);
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Error de conexión, intente más tarde.",
              });
            }, 200);
            console.log(e);
          }
        });
    }
  };

  const submitFormLogin = () => (event) => {
    event.preventDefault();

    setErrorFieldLogin(false);
    setLoaderLogin(true);

    if (values.password.length === 0) {
      setErrorFieldLogin(true);
      setLoaderLogin(false);
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo contraseña es obligatorio!",
      });
    } else {
      const data = {
        // email: values.mail.toLowerCase().trim(),
        email: values.mail.trim(),
        password: values.password,
        localIp: "127.0.0.1",
        publicIp: ipPublic,
      };

      axios
        .post(`${urlServices}auth/login`, data, {
          headers: {
            application: keyAplication,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setValues({
              ...values,
              status: response.data.user.status,
              session: response.data.jwt,
              user: response.data.user.name,
              id: response.data.user.id,
              profile_id: response.data.user.profileId,
              allname: response.data.user.username,
            });

            localStorage.setItem("status", response.data.user.status);
            localStorage.setItem("Session", response.data.jwt);
            localStorage.setItem("user", response.data.user.name);
            localStorage.setItem("id", response.data.user.id);
            localStorage.setItem("profile_id", response.data.user.profileId);
            localStorage.setItem("allname", response.data.user.username);
            setLoaderLogin(false);
            redirectProgile(response.data.user.profileId);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setLoaderLogin(false);
            setValidateEmail(false);
            setValues({ ...values, password: "" });
            setAlert({
              ...alert,
              openAlert: true,
              mensaje_error: "Contraseña incorrecta!",
            });

            loadCaptchaEnginge(4, "#bdbdbd", "#000", "upper");
          } else {
            setTimeout(() => {
              setLoaderLogin(false);
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Error de conexión, intente más tarde.",
              });
            }, 200);
            console.log(e);
          }
        });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ openAlert: false });
  };

  const handleOpenPasswordRecovery = () => {
    setpasswordRecovery(true);
  };

  const handleClosePasswordRecovery = () => {
    setpasswordRecovery(false);
  };

  const redirectProgile = (profile) => {
    if (profile === 1) {
      localStorage.setItem("controller", "/superadmin");
      localStorage.setItem("controllerRouter", "/superadmin/dashboard");

      setAlert({ ...alert, loggedIn: true});
    } else {
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "Está en desarrollo el dashboard",
      });

      /* localStorage.setItem("controller", "/admin");
      localStorage.setItem("controllerRouter", "/admin/dashboard");

      setAlert({ ...alert, loggedIn: true });*/
    }
  };

  if (alert.loggedIn) {
    return (
      <Redirect
        from={localStorage.getItem("controller")}
        to={localStorage.getItem("controllerRouter")}
      />
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            borderRadius: "5%",
            background: "white",
            overflow: "hidden",
            display: "grid",
            margin: "53px 10px 2px 5px",
            border: "1px solid rgb(241, 241, 241)",
          }}
        >
          {loaderLogin ? <ColorLinearProgress /> : ""}

          <Box
            style={{
              padding: "15px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              opacity: loaderLogin ? "0.4" : 1,
            }}
          >
            <center>
              <img
                src={logo}
                alt="validate"
                style={{
                  width: 100,
                  marginTop: 3,
                  objectFit: "contain",
                  borderRadius: "50%",
                }}
              />
            </center>
            <div className={classes.titleCard}>Iniciar sesión web</div>
            <Box
              component="form"
              noValidate
              //  onSubmit={handleSubmit}
              style={{ width: "80%" }}
            >
              <Fragment>
                {!validateEmail ? (
                  <Animated
                    animationIn="slideInLeft"
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <div style={{ marginLeft: "10%" }}>
                      <TextField
                        autoComplete="off"
                        name="user"
                        value={values.mail}
                        onChange={handleChange("mail")}
                        label="Correo electrónico"
                        error={values.errorMail}
                        className={classes.textField}
                        type="email"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                          maxLength: 150,
                        }}
                        variant="outlined"
                        Element
                      />
                    </div>
                    <Grid
                      container
                      spacing={3}
                      style={{ padding: "15px 15px" }}
                    >
                      <Grid item xs={6} md={6}>
                        <div style={{ float: "right" }}>
                          <LoadCanvasTemplate
                            reloadColor="#024242"
                            reloadText="Actualizar"
                          />
                        </div>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <TextField
                          id="user_captcha_input"
                          name="captcha"
                          value={values.captcha}
                          onChange={handleChange("captcha")}
                          label="Código"
                          variant="outlined"
                          error={values.errorCaptcha}
                          margin="dense"
                          inputProps={{
                            style: {
                              textAlign: "center",
                              width: 100,
                            },
                            maxLength: 4,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <center>
                      <Button
                        className={classes.buttonSubmit}
                        onClick={submitEmail()}
                        size="large"
                        endIcon={<ArrowRightAlt />}
                      >
                        Validar
                      </Button>
                    </center>
                  </Animated>
                ) : (
                  <Fragment>
                    <form onSubmit={submitFormLogin()}>
                      <Animated
                        animationIn="slideInLeft"
                        animationInDuration={500}
                        isVisible={true}
                      >
                        <center>
                          <FormControl className={classes.textField}>
                            <InputLabel htmlFor="standard-adornment-password">
                              Contraseña
                            </InputLabel>
                            <Input
                              autoFocus
                              name="password"
                              type={values.showPassword ? "text" : "password"}
                              value={values.password}
                              onChange={handleChange("password")}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {values.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              error={errorFieldLogin}
                              inputProps={{
                                style: {
                                  textAlign: "center",
                                  fontSize: 28,
                                },
                              }}
                            />
                          </FormControl>
                          <br></br>
                          <br></br>
                          <Button
                            className={classes.buttonSubmit}
                            onClick={submitFormLogin()}
                            color="primary"
                            size="large"
                          >
                            Iniciar sesión
                          </Button>
                        </center>
                      </Animated>
                    </form>

                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <center>
                          <Button
                            className={classes.textBotom}
                            aria-haspopup="true"
                            onClick={handleOpenPasswordRecovery}
                            style={loaderLogin ? { opacity: "0.4" } : null}
                          >
                            Recuperar contraseña
                          </Button>
                        </center>
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            </Box>
          </Box>
          <img
            src={banner}
            alt="banner"
            style={{
              width: "100%",
              height: 80,
              objectFit: "fill",
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>

      {alert.openAlert ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseAlert}
        >
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              elevation={6}
              variant="filled"
            >
              {alert.mensaje_error}
            </Alert>
          </Slide>
        </Snackbar>
      ) : (
        ""
      )}

      {passwordRecovery ? (
        <PasswordRecovery
          open={passwordRecovery}
          exit={handleClosePasswordRecovery}
        />
      ) : (
        ""
      )}
    </>
  );
}
