import React, { useState, useContext, useEffect } from "react";

import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { BubbleChart, Person, Payment } from "@material-ui/icons";
import CountUp from "react-countup";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import format from "date-fns/format";

import welcome from "assets/img/welcomeadmin.png";
import imgLoader from "assets/img/progress.gif";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [loaderUsers, setLoaderUsers] = useState(false);
  const [loaderTransactions, setLoaderTransactions] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [rowsCountTransactions, setRowsCountTransactions] = useState(0);
  const [error, setError] = useState(false);
  const [errorTrasactions, setErrorTrasactions] = useState(false);

  const fecha = new Date();
  const monthDate = fecha.getMonth() + 1;
  const yearDate = fecha.getFullYear();

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    userAccount();
    userTransactiosMonth();
  }, []);

  const userAccount = async () => {
    try {
      await axios
        .get(`${urlServices}users`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            let result = response.data.filter(
              (filtered) => filtered.status === 1
            );

            setRowsCount(result.length);
            setLoaderUsers(true);
          }
        })
        .catch((e) => {
          setRowsCount(0);
          setError(true);
          setLoaderUsers(true);
          console.log(e);
        });
    } catch (error) {
      setRowsCount(0);
      setError(true);
      setLoaderUsers(true);
      console.error(error);
      throw error;
    }
  };

  const userTransactiosMonth = async () => {
    try {
      await axios
        .get(`${urlServices}produbanco-transactions`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          let result = response.data.filter(
            (filtered) =>
              parseInt(format(new Date(filtered.createdAt), "MM")) ===
                monthDate &&
              parseInt(format(new Date(filtered.createdAt), "yyyy")) ===
                yearDate
          );

          setRowsCountTransactions(result.length);
          setLoaderTransactions(true);
        })
        .catch((e) => {
          setRowsCountTransactions(0);
          setErrorTrasactions(true);
          setLoaderTransactions(true);
          console.log(e);
        });
    } catch (error) {
      setRowsCountTransactions(0);
      setErrorTrasactions(true);
      setLoaderTransactions(true);
      console.error(error);
      throw error;
    }
  };

  return (
    <div>
      {/*<GridContainer>
        { <Grid item xs={12} sm={12}>
          <center>
            {error ? (
              <Alert severity="error">Error en con la conexión</Alert>
            ) : (
              ""
            )}
          </center>
        </Grid>}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <ViewModule />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Store />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <HowToReg />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Place />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>*/}

      <div className={classes.carDashboard}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className={classes.titleCard}>
              <BubbleChart className={classes.iconTheme} /> Bienvenido(a) al
              panel administrativo
            </div>
            <br></br>
            <br></br>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Person />
                </CardIcon>
                {error ? (
                  <Alert severity="error" icon={false}>
                    <b>Error en la consulta.</b>
                  </Alert>
                ) : (
                  ""
                )}

                <h3 className={classes.cardTitle}>
                  {loaderUsers ? (
                    <CountUp
                      className="account-balance"
                      start={0}
                      end={rowsCount}
                      duration={10}
                      useEasing={true}
                    />
                  ) : (
                    <>
                      <img
                        src={imgLoader}
                        alt="loader"
                        style={{
                          width: 40,
                          objectFit: "contain",
                          float: "right",
                        }}
                      />
                      <br></br>
                    </>
                  )}
                </h3>
                <p className={classes.cardCategory}>CUENTAS REGISTRADAS</p>
              </CardHeader>
            </Card>
            <br></br>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Payment />
                </CardIcon>
                {errorTrasactions ? (
                  <Alert severity="error" icon={false}>
                    <b>Error en la consulta.</b>
                  </Alert>
                ) : (
                  ""
                )}
                <h3 className={classes.cardTitle}>
                  {loaderTransactions ? (
                    <CountUp
                      className="account-balance"
                      start={0}
                      end={rowsCountTransactions}
                      duration={10}
                      useEasing={true}
                    />
                  ) : (
                    <>
                      <img
                        src={imgLoader}
                        alt="loader"
                        style={{
                          width: 40,
                          objectFit: "contain",
                          float: "right",
                        }}
                      />
                      <br></br>
                    </>
                  )}
                </h3>
                <p className={classes.cardCategory}>
                  TRANSACCIONES REALIZADAS EN EL MES
                </p>
              </CardHeader>
            </Card>
            {/*<br></br>
              <div className={classes.fontParagraph}>
                <b>tus pagos</b> sjdfhjs jhjsdhfjsdhfjs fhjshfjsfhsjfhj
                jhjhjsdhfjsdhfjsdf hfsdjf hsjdfhsjdfhsdjf hsdfjhsdfs djfhsdfsdjf
                hsdjfhsdjk.
              </div>*/}
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: "40px 0" }}>
            <center>
              <img src={welcome} alt="welcome" className={classes.cardImg} />
            </center>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
