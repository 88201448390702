import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function FooterAccount() {
  const classes = useStyles();
  return (
    <Hidden smDown>
      <div className={classes.footer2}>
        <p className={classes.right}>
          <span>tuspagos - {1900 + new Date().getYear()} COPYRIGHT&copy;</span>
        </p>
      </div>
    </Hidden>
  );
}
