import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/SidebarAdmin";

import routes from "routes";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/img/logomain.jpeg";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.submenu.length === 0) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        prop.submenu.map((prop2) => {
          return (
            <Route
              path={prop.layout + prop2.path}
              component={prop.component}
              key={key}
            />
          );
        });
      }
    })}
    <Redirect from="/superadmin" to="/superadmin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function SuperAdmin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();

  const [alert] = React.useState({
    loggedIn: localStorage.getItem("Session") === null ? false : true,
  });

  let color = "black";
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!alert.loggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logo={logo}
        // image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
