import React, { Fragment, useState, useEffect, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import NumberFormat from "react-number-format";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
//import FileBase64 from "react-file-base64";
//import Resizer from "react-image-file-resizer";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

import img from "assets/img/undraw_handcrafts_user.png";
import imgLoader from "assets/img/progress.gif";

const useStyles = makeStyles(cardBodyStyle);

/*const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      "100%",
      "100%",
      "JPEG",
      18,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });*/

export default function MyAccount(props) {
  const classes = useStyles();
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [openBackdrop, setoOpenBackdrop] = useState(false);
  const [error, setError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [loader, setLoader] = useState(true);

  const { urlServices, keyAplication, urlLocalMediaProfile } =
    useContext(UrlServicesContext);

  const [values, setValues] = useState({
    name: "",
    lastName: "",
    address: "",
    mail: "",
    phoneNumber: "",
    documentId: "",
    picture: "",
    pictureBase64: "",
    errorName: false,
    errorLastName: false,
    errorDocumentId: false,
    errorAddress: false,
    errorPhoneNumber: false,
  });

  let id = localStorage.getItem("id");
  let keyAuthorization = localStorage.getItem("Session");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    axios
      .get(`${urlServices}users/${id}`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        setValues({
          name: response.data.name,
          lastName: response.data.lastName,
          address: response.data.address,
          mail: response.data.email,
          phoneNumber: response.data.mobileNumber,
          documentId: response.data.documentId,
          picture:
            response.data.picture === null
              ? ""
              : urlLocalMediaProfile + response.data.picture,
          pictureBase64: "",
        });
        setLoader(false);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 200);
        } else {
          setError(true);
          setLoader(false);
        }
      });
  }, [urlServices, keyAuthorization, keyAplication, id, urlLocalMediaProfile]);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorLastName: false,
      errorAddress: false,
      errorPhoneNumber: false,
      errorDocumentId: false,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");

    if (values.name === "") {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombre" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else if (values.lastName === "") {
      setValues({ ...values, errorLastName: true });
      setMensaje('El campo "Apellidos" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.documentId === "") {
      setValues({ ...values, errorDocumentId: true });
      setMensaje('El campo "Documento" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else if (values.phoneNumber === "") {
      setValues({ ...values, errorPhoneNumber: true });
      setMensaje('El campo "Teléfono" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else if (values.address === "") {
      setValues({ ...values, errorAddress: true });
      setMensaje('El campo "Dirección" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else {
      setoOpenBackdrop(true);

      const data = {
        name: values.name,
        lastName: values.lastName,
        documentId: values.documentId,
        address: values.address,
        mobileNumber: values.phoneNumber,
      };

      if (values.pictureBase64 !== "") {
        data.picture = values.pictureBase64;
      }

      axios
        .put(`${urlServices}users/${id}`, data, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            setoOpenBackdrop(false);
            setError("success");
            setMensaje("Datos guardados con exito.");
            setOpenAlert(true);
          },
          (error) => {
            console.log(error);
            setError("error");
            setMensaje("Error al registrar los datos.");
            setOpenAlert(true);
            setoOpenBackdrop(false);
          }
        );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  /*const uploadSingleFile = async (e) => {
    let image = "",
      outfile = "";

    if (e.length > 0 && e.length <= 1) {
      if (Number(e[0].file.size) > 500000) {
        // SI ES MAYOR A 500KB LE BAJAMOS EL PESO
        image = await resizeFile(e[0].file);
        // const newFile = dataURIToBlob(image);
        outfile = image;
      } else {
        outfile = e[0].base64;
      }
      setValues({ ...values, pictureBase64: outfile });
    }
  };*/

  if (returnLogin) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.containerProfile}>
        {loader ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <center>
                <img
                  src={imgLoader}
                  alt="loader"
                  style={{
                    width: 50,
                    margin: "100px 0",
                    objectFit: "contain",
                  }}
                />
              </center>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Mi cuenta</div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <center>
                  <img
                    alt="avatar"
                    src={
                      values.picture === ""
                        ? img
                        : values.pictureBase64 !== ""
                        ? values.pictureBase64
                        : values.picture
                    }
                    className={classes.imgUserProfile}
                  />
                  {/*<br></br>
                <label className={classes.buttonSubmit2}>
                  <FileBase64 multiple={true} onDone={uploadSingleFile} />
                  <i class="fa fa-cloud-upload"></i> Seleccionar foto
                </label>*/}
                </center>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange("name")}
                        fullWidth
                        required
                        label="Nombres"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="lastname"
                        value={values.lastName}
                        error={values.errorLastName}
                        onChange={handleChange("lastName")}
                        fullWidth
                        required
                        label="Apellidos"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="mail"
                        value={values.mail}
                        error={values.errorMail}
                        onChange={handleChange("mail")}
                        fullWidth
                        required
                        label="Correo electrónico"
                        margin="dense"
                        autoComplete="off"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <NumberFormat
                      customInput={TextField}
                      name="documentId"
                      value={values.documentId}
                      onChange={handleChange("documentId")}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          fontSize: 16,
                        },
                      }}
                      label="Documento"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      error={values.errorDocumentId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <NumberFormat
                      customInput={TextField}
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange("phoneNumber")}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          fontSize: 16,
                        },
                      }}
                      label="Teléfono"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      error={values.errorPhoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="mail"
                        value={values.address}
                        error={values.errorAddress}
                        onChange={handleChange("address")}
                        fullWidth
                        required
                        label="Dirección"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <center>
                  <Button
                    type="submit"
                    className={classes.buttonContactGreen}
                    onClick={submitForm}
                  >
                    Guardar
                  </Button>
                </center>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Backdrop
        style={{ zIndex: "9999", color: "#FFFFFF" }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error === "error" ? "error" : "success"}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
    </Fragment>
  );
}
