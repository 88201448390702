import React from "react";
import { Redirect } from "react-router-dom";

export default function Return() {
  const session = localStorage.getItem("Session") === null ? false : true;

  if (session) {
    return (
      <Redirect
        from={localStorage.getItem("controller")}
        to={localStorage.getItem("controllerRouter")}
      />
    );
  } else {
    return <Redirect to="/login" />;
  }
}
