import React, { createContext, useState } from "react";

const UrlServicesContext = createContext();
const { Provider, Consumer } = UrlServicesContext;

function UrlServicesProvider({ children }) {
  /* ### URL BASE ### */
  //const [urlBase] = useState("http://localhost:3000/");
  //const [urlBase] = useState("https://polygraph.web.dev.aplios.software/");
  //const [urlBase] = useState("https://nuevo.poligrafosecuador.com/");

  /* ### API ### */
  const [urlServices] = useState("https://apidev.tuspagos.ec/");
  //const [urlServices] = useState("http://192.168.0.2:4000/");

  /* ### PROFILE ### */
  /* const [urlLocalMediaProfile] = useState(
    "https://polygraph.web.dev.aplios.software/files/accounts/"
  );
  const [urlLocalMediaProfile] = useState(
    "https://nuevo.poligrafosecuador.com/files/accounts/"
  );*/

  /* ### LOAD SERVICES KEYAPLICATION */
  const [keyAplication] = useState("OGMGDuYv3QRwtzUQOpge");

  return (
    <Provider
      value={{
        urlServices,
        // urlLocalMediaProfile,
        keyAplication,
        // urlBase,
      }}
    >
      {children}
    </Provider>
  );
}

export {
  UrlServicesProvider,
  Consumer as urlServicesConsumer,
  UrlServicesContext,
};
